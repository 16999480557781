@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Antonio";
  src: local("Antonio"), url("../src/font/Antonio.ttf") format("truetype");
}

.antonio {
  font-family: 'Antonio';
}

.outline-text {
  -webkit-text-stroke: 1px white;
}